.nc-fill-container {
    min-height: calc(100vh - 153px);
}

.bg-primary-color {
    background-color: rgb(215, 152, 254);
}

.text-primary-color {
    color: rgb(215, 152, 254);
}

.border-radius-50 {
    border-radius: 50%;
}

.nc-CardCategory5 {
    margin: 0 10px;
}

.btm-gradient {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 5%, rgb(0 0 0 / 48%) 30%, rgb(0 0 0 / 87%) 70%, rgb(0 0 0) 100%, rgb(0 0 0) 100%), no-repeat;
}
.ghost-image {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.bradi {
    border-radius: "0.25rem" !important;
}
/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #161616 inset !important;
    border: '1px solid #FFFFFF1C' !important;
   
    
}
 */
input:-webkit-autofill {
    border: none;
    
    caret-color: #fff; 
    color: #fff;
    background: #161616 ;
  
    -webkit-text-fill-color: #fff; 
    -webkit-background-clip: text; 
    -webkit-box-shadow: 0 0 0 30px #161616 inset !important;
}

.swal-modal {
    background-color: rgb(43 43 43)
}
.swal-button {
    background-color: rgba(229, 191, 0);
    border: none;
    color: rgb(5, 5, 5);
}
.swal-button:hover {
    background-color: rgba(229, 191, 0) !important;
}
.swal-title, .swal-text {
    color: #fff;
}