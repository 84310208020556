.custom-range-slider {
    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
        width: 100%;
      }
      
      /* Removes default focus */
      input[type="range"]:focus {
        outline: none;
      }
      
      /******** Chrome, Safari, Opera and Edge Chromium styles ********/
      /* slider track */
      input[type="range"]::-webkit-slider-runnable-track {
        background-color: #e5bf00;
        border-radius: 0.5rem;
        height: 10px;
      }
      
      /* slider thumb */
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: -5px; /* Centers thumb on the track */
        background-color: #e5bf00;
        border-radius: 19.5rem;
        height: 20px;
        width: 20px;
        position: relative;
      }
      
      input[type="range"]:focus::-webkit-slider-thumb {
        outline: 3px solid #e5bf00;
        outline-offset: 0.125rem;
      }
      
      /*********** Firefox styles ***********/
      /* slider track */
      input[type="range"]::-moz-range-track {
        background-color: #e5bf00;
        border-radius: 0.5rem;
        height: 10px;
      }
      
      /* slider thumb */
      input[type="range"]::-moz-range-thumb {
        background-color: #e5bf00;
        border: none; /*Removes extra border that FF applies*/
        border-radius: 19.5rem;
        height: 20px;
        width: 20px;
      }
      
      input[type="range"]:focus::-moz-range-thumb{
        outline: 3px solid #e5bf00;
        outline-offset: 0.125rem;
      }
}