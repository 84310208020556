:root {
  --c-primary-50: 255, 240, 163;
  --c-primary-100: 255, 236, 143;
  --c-primary-200: 255, 233, 122;
  --c-primary-300: 255, 230, 102;
  --c-primary-400: 255, 223, 61;
  --c-primary-500: 255, 219, 41;
  --c-primary-600: 255, 216, 20;
  --c-primary-700: 255, 213, 0;
  --c-primary-800: 235, 196, 0;
  --c-primary-900: 229, 191, 0;

  // SECONDARY COLOR
  --c-secondary-50: 240, 253, 244;
  --c-secondary-100: 220, 252, 231;
  --c-secondary-200: 187, 247, 208;
  --c-secondary-300: 134, 239, 172;
  --c-secondary-400: 74, 222, 128;
  --c-secondary-500: 34, 197, 94;
  --c-secondary-600: 22, 163, 74;
  --c-secondary-700: 21, 128, 61;
  --c-secondary-800: 22, 101, 52;
  --c-secondary-900: 20, 83, 45;

  // NEUTRAL COLOR
  --c-neutral-50: 249, 250, 251;
  --c-neutral-100: 243, 244, 246;
  --c-neutral-200: 229, 231, 235;
  --c-neutral-300: 209, 213, 219;
  --c-neutral-400: 156, 163, 175;
  --c-neutral-500: 107, 114, 128;
  --c-neutral-600: 75, 85, 99;
  --c-neutral-700: 55, 65, 81;
  --c-neutral-800: 16, 11, 19;
  --c-neutral-900: 0, 0, 0;

  --c-website-500: 5, 5, 5;
  --c-website-480: 48, 48, 48;
  --c-website-470: 47, 47, 47;
  --c-website-220: 22, 22, 22;
}