$primary: #E5BF00;

#sale-progress-content {
  background: $primary;
  color: #fff;
  border-radius: 2px;
  padding: 3px 7px;
  margin: 0px 10px;
  text-align: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    height: 0;
    width: 0;
    border: solid 6px $primary;
    z-index: -1;
    border-top-color: white;
    border-bottom-color: white;
    border-left-color: white;
  }
}

/* CHROME */
#range {
  -webkit-appearance: none;
  outline: none;
  background: $primary;
  height: 6px;
  width: 100%;
  border-radius: 5px;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: $primary;
  }
}

/* FIREFOX */
#range::-moz-range-thumb {
  border: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
}

#range::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: $primary;
  border-radius: 5px;
}